import React from 'react';
import replace from "lodash/replace";
import Image from 'next/image';
import Box from '@mui/material/Box';
import * as SvgIconsComponent from '@/components/icons';

import ApparecchiDaIncasso from '@/public/menu-icon/apparecchi-da-incasso.png';
import ArmatureStagne from '@/public/menu-icon/armature-stagne.png';
import ArmatureStradali from '@/public/menu-icon/armature-stradali.png';
import ArredoUrbano from '@/public/menu-icon/arredo-urbano.png';
import Atex from '@/public/menu-icon/atex.png';
import HumanCentricLighting from '@/public/menu-icon/human-centric-lighting.png';
import IlluminazioneClassica from '@/public/menu-icon/illuminazione-classica.png';
import InterniCiviliECommerciali from '@/public/menu-icon/interni-civili-e-commerciali.png';
import Orticoltura from '@/public/menu-icon/orticoltura.png';
import Pali from '@/public/menu-icon/pali.png';
import ProiettoriDisano from '@/public/menu-icon/proiettori-disano.png';
import Residenziali from '@/public/menu-icon/residenziali.png';
import Sanificazione from '@/public/menu-icon/sanificazione.png';
import SistemiDiGestioneDellaLuce from '@/public/menu-icon/sistemi-di-gestione-della-luce.png';
import SospensioniCiviliEIndustriali from '@/public/menu-icon/sospensioni-civili-e-industriali.png';
import Arredi from '@/public/menu-icon/arredi.png';
import BlueLine from '@/public/menu-icon/blue-line.png';
import IncassiFissi from '@/public/menu-icon/incassi-fissi.png';
import IncassiOrientabili from '@/public/menu-icon/incassi-orientabili.png';
import ProiettoriFosnova from '@/public/menu-icon/proiettori-fosnova.png';
import Sistemi from '@/public/menu-icon/sistemi.png';
import BinariDisano from '@/public/menu-icon/binari-disano.png';
import BinariFosnova from '@/public/menu-icon/binari-fosnova.png';

/**
 * Icons Component map.
 *
 * @param {string} name Icon Name.
 * @returns {*}
 */
export const getIconComponentByName = (name: string | undefined) => {
  if (!name) {
    return;
  }

	const ComponentsMap: Record<string, React.FC> = {
		facebook: SvgIconsComponent.FooterFacebook,
		instagram: SvgIconsComponent.FooterInstagram,
		twitter: SvgIconsComponent.FooterTwitter,
		youtube: SvgIconsComponent.FooterYoutube,
    linkedin: SvgIconsComponent.FooterLinkedin,
    search: SvgIconsComponent.SearchIcon,
    caretDown: SvgIconsComponent.CaretDownIcon,
    arrowSquare: SvgIconsComponent.ArrowSquareIcon,
    burgerIcon: SvgIconsComponent.BurgerIcon,
    closeIcon: SvgIconsComponent.CloseIcon,
		chevronRight: SvgIconsComponent.ChevronRight,
    ISO14001: SvgIconsComponent.ISO14001,
    ISO9001: SvgIconsComponent.ISO9001,
    normative1: SvgIconsComponent.Normative1,
    normative2: SvgIconsComponent.Normative2,
    normative3: SvgIconsComponent.Normative3,
    qualita1: SvgIconsComponent.Qualita1,
    qualita2: SvgIconsComponent.Qualita2,
    qualita3: SvgIconsComponent.Qualita3,
    assemblaggio: SvgIconsComponent.Assemblaggio,
    cablaggio: SvgIconsComponent.Cablaggio,
    stampaggio: SvgIconsComponent.Stampaggio,
    profilatura: SvgIconsComponent.Profilatura,
    verniciatura: SvgIconsComponent.Verniciatura,
		productInfoIP20: SvgIconsComponent.ProductInfoIP20,
		productInfoCE: SvgIconsComponent.ProductInfoCE,
		productInfoDIMM: SvgIconsComponent.ProductInfoDIMM,
		productInfoENEC: SvgIconsComponent.ProductInfoENEC,
		productInfoIK07: SvgIconsComponent.ProductInfoIK07,
		productInfoLOWFLICKER: SvgIconsComponent.ProductInfoLOWFLICKER,
		productInfoRG0: SvgIconsComponent.ProductInfoRG0,
		productInfoURG16: SvgIconsComponent.ProductInfoURG16,
		productInfoUV: SvgIconsComponent.ProductInfoUV,
		document: SvgIconsComponent.Document,
		download: SvgIconsComponent.Download,
		plus: SvgIconsComponent.Plus,
		minus: SvgIconsComponent.Minus,
		pdf: SvgIconsComponent.Pdf,
    dxf: SvgIconsComponent.Dxf,
    '3ds': SvgIconsComponent.Treds,
    jpg: SvgIconsComponent.Jpg,
    ldt: SvgIconsComponent.Ldt,
    uld: SvgIconsComponent.Uld,
    ies: SvgIconsComponent.Ies,
    zip: SvgIconsComponent.Zip,
    default: SvgIconsComponent.NoFileType,
		checkbox: SvgIconsComponent.Checkbox,
		checkboxChecked: SvgIconsComponent.CheckboxChecked,
    closeCircleIcon: SvgIconsComponent.CloseCircleIcon,
    closeCircleBlackIcon: SvgIconsComponent.CloseCircleBlackIcon,
	};

  const MenuIconMap: Record<string, StaticImageData> = {
    'apparecchi-da-incasso': ApparecchiDaIncasso,
    'armature-stagne': ArmatureStagne,
    'armature-stradali': ArmatureStradali,
    'arredo-urbano': ArredoUrbano,
    'atex': Atex,
    'human-centric-lighting': HumanCentricLighting,
    'illuminazione-classica': IlluminazioneClassica,
    'interni-civili-e-commerciali': InterniCiviliECommerciali,
    'orticoltura': Orticoltura,
    'pali': Pali,
    'proiettori-disano': ProiettoriDisano,
    'residenziali': Residenziali,
    'sanificazione': Sanificazione,
    'sistemi-di-gestione-della-luce': SistemiDiGestioneDellaLuce,
    'sospensioni-civili-e-industriali': SospensioniCiviliEIndustriali,
    'arredi': Arredi,
    'blue-line': BlueLine,
    'incassi-fissi': IncassiFissi,
    'incassi-orientabili': IncassiOrientabili,
    'proiettori-fosnova': ProiettoriFosnova,
    'sistemi': Sistemi,
    'binari-disano': BinariDisano,
    'binari-fosnova': BinariFosnova,
  };

  const menuIconName = name.substr('menu-'.length);

	if (name in ComponentsMap) {
		const IconComponent = ComponentsMap[name];
    const altText = name.indexOf('-') === -1 ? name : replace(name, '-', ' ');
    /* @ts-ignore */
		return <IconComponent alt={altText} />;
	} else if (name.indexOf('menu-') === 0 && menuIconName in MenuIconMap) {
    const imageData = MenuIconMap[menuIconName];
    return <Image src={imageData} alt={replace(menuIconName, '-', ' ')} />;
  } else {
		return <div>Icon not found</div>;
	}
};

export const buildHeaderMenuIcon = (iconName: string) => (<Box sx={{
  flexShrink: 0,
  width: 52,
  textAlign: 'center',
}}>
  {getIconComponentByName(iconName)}
</Box>)
