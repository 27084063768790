import React from 'react';
import { WPLink, WPLinks_COLORE, WPLinks_SIZE, WPLinks_VARIANTE } from '@/data/wp/operations/queries/fragments/link';
import OmniLink from './OmniLink';
import styled from '@mui/system/styled';
import Stack, { StackProps } from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { getIconComponentByName } from '@/utils/icons-map';

const StackWrapMode = styled(Stack)({
  flexWrap: 'wrap',
  '& .MuiButton-root': {
    flexGrow: 1
  }
}) as typeof Stack;

export interface CmsLinksProps extends StackProps {
  data: WPLink[]
  enableWrap?: boolean
}

/**
 * Given a list of link info coming from the CMS, it prints them out using the MUI Stack component.
 * CmsLinks accept all of the props of Stack (eg: spacing, direction, justifyContent, etc): https://mui.com/components/stack/
 */
const CmsLinks: React.FC<CmsLinksProps> = ({ data, enableWrap = false, spacing = 2.5, ...rest }) => {
  const StackComp = enableWrap ? StackWrapMode: Stack;
  const stackSpacing = enableWrap ? 0: spacing;
  return <StackComp gap={enableWrap ? spacing: undefined} spacing={stackSpacing} {...rest}>
    {data.map((link, index) => <CmsLink key={index} data={link}></CmsLink>)}
  </StackComp>;
}

const mapLinkColor = (colorData: WPLinks_COLORE) => {
  switch (colorData) {
    case WPLinks_COLORE.error:
      return 'error';
    case WPLinks_COLORE.secondary:
      return 'secondary';
    case WPLinks_COLORE.success:
      return 'success';
    default: // WPLink_COLORE.primary
      return 'primary';
  }
}

const mapLinkSize = (sizeData: WPLinks_SIZE) => {
  switch (sizeData) {
    case WPLinks_SIZE.large:
      return 'large';
    case WPLinks_SIZE.small:
      return 'small';
    default: // WPLinks_SIZE.medium
      return 'medium';
  }
}

type InternalLinkInfo = {
  url: string
  title: string
  target: string
  download: boolean
  variant: WPLinks_VARIANTE
  color: ReturnType<typeof mapLinkColor>
  size: ReturnType<typeof mapLinkSize>
  icon?: string
  disableClientSideNavigation: boolean
}

export default CmsLinks;

export type CmsLinkProps = {
  data: WPLink
}

const CmsLink: React.FC<CmsLinkProps> = ({ data }) => {
  const linkInfo: InternalLinkInfo = {
    url: data.linkInterno ? data.linkInterno.uri : (data.url ? data.url : ''),
    title: data.label ? data.label : (data.linkInterno ? data.linkInterno.title : ''),
    target: data.target || data.download ? '_blank': '',
    download: data.download ? true: false,
    variant: data.variante,
    color: mapLinkColor(data.colore),
    size: mapLinkSize(data.dimensione),
    icon: data.icon ? data.icon : '',
    disableClientSideNavigation: data.disableClientSideNavigation || false,
  };

  if (!linkInfo) {
    return <></>;
  }

  switch (linkInfo.variant) {
    case WPLinks_VARIANTE.textButton:
      return <Button variant="contained" component={OmniLink} noLinkStyle locale={false} href={linkInfo.url} target={linkInfo.target} download={linkInfo.download} color={linkInfo.color} size={linkInfo.size} disableClientSideNavigation={linkInfo.disableClientSideNavigation}>{linkInfo.title}</Button>
    case WPLinks_VARIANTE.filledButton:
      return <Button variant="contained" component={OmniLink} locale={false} href={linkInfo.url} target={linkInfo.target} download={linkInfo.download} color={linkInfo.color} size={linkInfo.size} disableClientSideNavigation={linkInfo.disableClientSideNavigation}>{linkInfo.title}</Button>
      case WPLinks_VARIANTE.filledButtonIcon:
      return <Button variant="contained" component={OmniLink} locale={false} href={linkInfo.url} target={linkInfo.target} download={linkInfo.download} color={linkInfo.color} size={linkInfo.size} sx={{ justifyContent: 'space-between' }} disableClientSideNavigation={linkInfo.disableClientSideNavigation}>{linkInfo.title}{linkInfo.icon !== undefined && getIconComponentByName(linkInfo.icon)}</Button>
    case WPLinks_VARIANTE.outlinedButton:
      return <Button variant="outlined" component={OmniLink} locale={false} href={linkInfo.url} target={linkInfo.target} download={linkInfo.download} color={linkInfo.color} size={linkInfo.size} disableClientSideNavigation={linkInfo.disableClientSideNavigation}>{linkInfo.title}</Button>
    default: // WPLinks_VARIANTE.plainLink
      return <OmniLink href={linkInfo.url} target={linkInfo.target} download={linkInfo.download} disableClientSideNavigation={linkInfo.disableClientSideNavigation}>{linkInfo.title}</OmniLink>
  }
}

export { CmsLink };
