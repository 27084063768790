
/**
 * @param baseType (ex: 'DefaultTemplate_Fasce_Fasce_StandoutBanner_Links')
 * @returns
 */
const LinksPartial = (baseType: string) => `
  __typename
  ... on ${baseType}_LinkInterno {
    linkInterno {
      __typename
      ... on ContentNode {
        id
        slug
        uri
      }
      ... on NodeWithTitle {
        title
      }
    }
    label
    target
    variante
    colore
    dimensione
  }
  ... on ${baseType}_LinkEsterno {
    url
    label
    target
    variante
    colore
    dimensione
  }
`
export default LinksPartial;

export enum WPLinks_VARIANTE {
  plainLink = 'plainLink',
  textButton = 'textButton',
  filledButton = 'filledButton',
  outlinedButton = 'outlinedButton',
  filledButtonIcon = 'filledButtonIcon',
}

export enum WPLinks_COLORE {
    primary = 'primary',
    secondary = 'secondary',
    success = 'success',
    error = 'error',
}

export enum WPLinks_SIZE {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface WPLink {
  __typename: string

  // da "Filed part – Link info":
  url?: string // valorizzato solo se __typename finisce con "_LinkEsterno"
  label?: string
  target: boolean | null
  download?: boolean | null // see https://developers.google.com/web/updates/2011/08/Downloading-resources-in-HTML5-a-download
  variante: WPLinks_VARIANTE
  colore: WPLinks_COLORE
  dimensione: WPLinks_SIZE

  linkInterno?: {
    id: string
    slug: string
    uri: string
    title: string
  }
  icon?: string

  disableClientSideNavigation?: boolean
}
